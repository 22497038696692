import React from "react";
import { Modal, Button } from "react-dsgov";
import useModalMetadadoState from "state/hooks/modais/useModalMetadadoState";
import styles from './MedadadosModal.module.scss';

const MetadadosModal = (props) => {
    const [modalMetadadoAberta, setModalMetadadoAberta] = useModalMetadadoState(false);


    let metadados = props.metadados;
    let categorias = new Set();
    if (metadados !== undefined && metadados !== null && metadados.length > 0) {
        categorias = new Set(metadados.map((item) => item.categoria));
    }
    return (
            <Modal useScrim
                   showCloseButton
                   modalOpened={modalMetadadoAberta} 
                   title={metadados[0].descricaoSerie}
                   onCloseButtonClick={() => setModalMetadadoAberta(false)}
            >
                <Modal.Body>
                    {Array.from(categorias).map((categoria) => {
                        const filteredMetadados = metadados.filter(
                            (item) => item.categoria === categoria
                        );

                        return (
                            <div>
                                    {filteredMetadados.map((item) => (
                                        <div className={styles.metadado} key={item.metadado}>
                                            <p className={styles.item}><b>{item.metadado}</b></p>
                                            <p>{item.valor}</p>
                                        </div>
                                    ))}
                            </div>
                        );
                    })}
                </Modal.Body>
            </Modal>

    );
};

export default MetadadosModal;   // Path: src/pages/Metadados/Metadados.jsx