import { Outlet } from "react-router";
import OverlayLoading from "../OverlayLoading/OverlayLoading";
import styles from './Layout.module.scss';
const Layout = () => {
  return <>
            <div className={styles.conteudo}>
                <Outlet />
            </div>
            <OverlayLoading />
        </>;
};
export default Layout;