import React, { useEffect } from 'react';
import { useState } from "react";
import { Table, Row, Col, Card, Checkbox, Button } from 'react-dsgov';
import CalculoValorService from 'services/CalculoValorService';
import SerieService from 'services/SerieService';
import useArraySeriesState from "state/hooks/arraySeriesState";
import { removeSerieDoArray, ajustaCheckbox } from 'util/funcoesComuns';
import useCheckBoxSerieMap from "state/hooks/useCheckBoxSerieMap";
import useMostraListaSeries from 'state/hooks/useMostraListaSeries';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mapValoresState } from 'state/atom';

const EdicaoSerie = () => {


    const [checkboxValorRealMap, setCheckboxValorRealMap] = useState(new Map());
    const [checkboxValorCorrenteMap, setCheckboxValorCorrenteMap] = useState(new Map());

    const [checkboxSerieMap, setCheckboxSerieMap] = useCheckBoxSerieMap(new Map());
    const [arraySeries, setArraySeries] = useArraySeriesState([]);
    const mapValores = useRecoilValue(mapValoresState);
    const setMapValores = useSetRecoilState(mapValoresState);
    const [mostraListaSeries, setMostraListaSeries] = useMostraListaSeries(false);


    const removeSerieDoArraySeries = (copiaSerie) => {
        removeSerieDoArray(arraySeries, copiaSerie, setArraySeries);
        ajustaCheckbox(copiaSerie, false, checkboxSerieMap, setCheckboxSerieMap);
    }


    useEffect(() => {


        if (arraySeries != null && arraySeries.length > 0) {
            arraySeries.forEach(serie => {
                if(!checkboxValorCorrenteMap.get(serie.id.toString())){
                    alteraCheckboxValorCorrente(serie.id, true);
                }
            });
        }

        
        let listaParaRemocao = [];
        
        checkboxValorCorrenteMap.forEach((valor, key) => {
            if (!arraySeries.find(serie => serie.id.toString() === key)) {
                listaParaRemocao.push(key);        
            }
        });

        listaParaRemocao.forEach(key => {
            alteraCheckboxValorCorrente(key, false);
            alteraCheckboxValorReal(key, false);
        });
        

    }, [arraySeries]);

    const alteraCheckboxValorCorrente = (idSerie, selecionado) => {
        checkboxValorCorrenteMap.set(idSerie.toString(), selecionado);
        setCheckboxValorCorrenteMap(checkboxValorCorrenteMap);

        if (selecionado) {
            SerieService.getValoresSerie(idSerie).then((valores) => {
                setMapValores((oldTodoList) => new Map([...oldTodoList, [idSerie.toString(), valores]]));
            });
        }
        else {
            setMapValores((oldTodoList) => new Map([...oldTodoList].filter(([key, value]) => key !== idSerie.toString())));
        }      
    }


    const alteraCheckboxValorReal = (idSerie, selecionado) => {
        checkboxValorRealMap.set(idSerie.toString() + ' - IPCA', selecionado);
        setCheckboxValorRealMap(checkboxValorRealMap);

        if (selecionado) {

            CalculoValorService.getDeflacionar(idSerie).then((valores) => {
                setMapValores((oldTodoList) => new Map([...oldTodoList, [idSerie.toString()+ ' - IPCA', valores]]));
            });
        } else {
            setMapValores((oldTodoList) => new Map([...oldTodoList].filter(([key, value]) => key !== idSerie.toString()+ ' - IPCA')));
        }

    }

    return (
        <>
            {mostraListaSeries && arraySeries.length > 0 &&
            <>
            <Row>
                <Col><h3>Séries Selecionadas</h3></Col>
                
            </Row>
                <Row>
                    
                    <Col>
                        <Card>
                            <Card.Content>
                                <Table
                                    showPagination={false}
                                    showSearch={false}
                                    showPageSelector={false}
                                    showDensityButtons={false}>
                                    <thead>
                                        <tr>
                                            <th className="border-bottom border-left" scope="col">Código</th>
                                            <th className="border-bottom border-left" scope="col">Nome</th>
                                            <th className="border-bottom border-left text-center" scope="col">Ações</th>
                                            <th className="border-bottom border-left" scope="col">Opções de Exibição no Gráfico</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {arraySeries.map((serie, index) => (
                                            <tr key={index}>
                                                <td>{serie.planoContas}</td>
                                                <td>{serie.descricao}</td>
                                                <td>
                                                    <div className="tr-ng-cell text-center">
                                                        <div className="ng-scope">
                                                            <div>
                                                                <span className="hint hint--left" data-hint="Limpar Seleção da Série">
                                                                    <Button secondary circle onClick={() => removeSerieDoArraySeries(serie)} className="btn fa fa-trash btn-danger btn-xs"></Button>
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Checkbox
                                                        id={`checkbox-valorCorrente-${serie.id}`}
                                                        label='Mensal - Valores Correntes'
                                                        value={`checkbox-corrente-01-${serie.id}`}
                                                        name="radio-items"
                                                        checked={checkboxValorCorrenteMap.get(serie.id.toString())}
                                                        onChange={(event) => alteraCheckboxValorCorrente(serie.id, event.target.checked)}
                                                    />
                                                    <br />
                                                    <Checkbox
                                                        id={`checkbox-valorReal-${serie.id}`}
                                                        label='Mensal - Valores Reais (IPCA)'
                                                        value={`checkbox-real-01-${serie.id}`}
                                                        name="radio-items"
                                                        checked={checkboxValorRealMap.get(serie.id.toString()+ ' - IPCA')}
                                                        onChange={(event) => alteraCheckboxValorReal(serie.id, event.target.checked)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </Table>
                            </Card.Content>
                            <Card.Footer>
                                <div className="d-flex">
                                    <div className="ml-auto">

                                    </div>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                </>
            }</>
    )
};

export default EdicaoSerie;


