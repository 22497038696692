import React, { useEffect } from "react";
import { Select } from "react-dsgov";
import TemaService from "../../services/TemaService";
import SubTemaService from "../../services/SubTemaService";
import SubTema from "../Subtema/Subtema";
import useSubTemasState from "../../state/hooks/useSubtemasState";
import ArvoreService from "services/ArvoreService";
import useArvoreState from "state/hooks/useArvoreState";
import MetadadosModal from "pages/Modais/Metadados/MetadadosModal";
import useMetadadoState from "state/hooks/useMetadadoState";
import useValoresState from "state/hooks/useValoresState";
import ValoresModal from "pages/Modais/Valores/ValoresModal";
import useLoadingState from "state/hooks/useLoadingState";

const Tema = () => {

    const [optionsTemas, setOptionsTemas] = React.useState([]);
    const [, setOptionsSubTemas] = useSubTemasState([]);
    const [filtros] = React.useState([]);
    const [, setArvoreState] = useArvoreState([]);
    const [metadadoState] = useMetadadoState([]);
    const [valoresState] = useValoresState([]);
    const [, setLoading] = useLoadingState(false);

 
    //Busca os subtemas e salvar no subTemasState
    const buscaSubTemas = (idTema) => {
        if(idTema !== undefined){
            SubTemaService.getSubTemas(idTema).then((subTemas) => {

                setLoading(true);

                let options = [];
                let mapSubTemaArvore = [];

                //ordenando subTema por nomeCompleto
                subTemas = subTemas.sort((a, b) => (a.nomeCompleto > b.nomeCompleto) ? 1 : -1);

                let count = 0;
                for (const subTema of subTemas) {
                    //para cada subtema eu recupero a arvore de series e adiciono num hashmap de subtema e arvore
                    ArvoreService.getArvore(subTema.id).then((arvore) => {
                        mapSubTemaArvore.push({subTema: subTema.id, arvore: arvore});
                        options.push({ label: subTema.nomeCompleto, subTema: subTema.id });
                        count++;
                        if(count === subTemas.length){
                            //ordena options por nomeCompleto
                            options = options.sort((a, b) => (a.label > b.label) ? 1 : -1);
                            setArvoreState(mapSubTemaArvore);
                            setLoading(false);
                            setOptionsSubTemas(options);
                        } 
                    });
                    
                    
                }

            });
        }
    };


    useEffect(() => {

        TemaService.getTemas().then((temas) => {

            const options = [];

            //ordenando temas por nomeCompleto
            temas = temas.sort((a, b) => (a.nomeCompleto > b.nomeCompleto) ? 1 : -1);

            for (const tema of temas) {
                options.push({ label: tema.nomeCompleto, value: tema.id });
            }

            setOptionsTemas(options);

        });

    }, []);

    return <>

        <h3>Temas</h3>
        <Select
            value={filtros.idTema}
            onChange={(value) => buscaSubTemas(value)}
            options={optionsTemas}
            placeholder="Selecione o Tema"
            style={{ maxWidth: "100%" }}
        />

        {metadadoState.length > 0 && <MetadadosModal metadados={metadadoState} />}
        {valoresState.length > 0 && <ValoresModal valores={valoresState} />}

    </>;


};
export default Tema;