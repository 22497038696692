import axios from 'axios';

class SerieService {
    
    static async getValoresSerie(idSerie) {
        const url = "https://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/SerieGrafico/ValorSerie/"+idSerie;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    const valores = response.data;
                    //for each valores, convert the "data" attribute to String dd/mm/yyyy
                    valores.forEach((valor) => {
                        valor.data = new Date(valor.data).toLocaleDateString("pt-BR");
                    });

                    resolve(valores);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    
    static async getDetalhesSerie(idSerie) {
        const url = "https://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/SerieGrafico/Detalhes/"+idSerie;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    static async getMetadadosSerie(idSerie) {
        const url = "https://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/SerieGrafico/Serie/"+idSerie;
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    
}
export default SerieService;