import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { useRecoilValue } from 'recoil';
import { mapValoresState } from 'state/atom';

const Grafico = () => {

  const valores = useRecoilValue(mapValoresState);
  const [graficoDados, setGraficoDados] = useState({});
  const [mostrarGrafico, setMostrarGrafico] = useState(false);

  useEffect(() => {

    setMostrarGrafico(false);

    //time series labels made by all valores.data of arraySeries
    let labels = [];
    let datasets = [];

    //for each key of mapValoresState, pushes its valores into labels
    valores.forEach((valores, key) => {
      if (valores.length > 0) {
        valores.forEach(valor => {
          labels.push(valor.data);
        });
      }
    });

    //removes all duplcated values of labels
    labels = [...new Set(labels)];

    //sorts labels
    labels.sort((a, b) => {
      return new Date(a) - new Date(b);
    });

    valores.forEach((valores, key) => {
      if (valores.length === 0) {
        return;
      }

      const randomRgbBorderColor = () => {
        let r = Math.floor(Math.random() * 255);
        let g = Math.floor(Math.random() * 255);
        let b = Math.floor(Math.random() * 255);
        return `rgb(${r},${g},${b})`;
      }

      let novoDataSet = { name: valores[0].serie, data: [], type: 'line', lineStyle: { color: randomRgbBorderColor() } };

      //sets novoDataSet.data with all values of serie.valores that maches with labels
      labels.forEach(label => {
        let valor = valores.find(valor => valor.data === label);
        if (valor != null) {
          novoDataSet.data.push(valor.valor);
        } else {
          novoDataSet.data.push(null);
        }
      });

      datasets.push(novoDataSet);
    });


    if(datasets.length > 0){
      setGraficoDados(
        {
          title: {
            text: 'Gráfico',
          },
          xAxis: {
            type: 'category',
            data: labels
          },
          yAxis: {
            type: 'value'
          },
          series: datasets,
          tooltip: {
            trigger: 'axis'
          },
          toolbox: {
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: 'none', title: { zoom: 'Zoom', back: 'Desfazer zoom' }
              },
              dataView: { readOnly: true, title: 'Ver dados', lang: ['Dados', 'Fechar', 'Atualizar'] },
              magicType: { type: ['line', 'bar'], title: { line: 'Linha', bar: 'Barra' } },
              restore: {title: 'Restaurar'},
              saveAsImage: {title: 'Salvar como imagem'}
            }
          },
          legend: {
            data: labels
          }
        });
        setMostrarGrafico(true);
    }
    

  }, [valores]);



  return (<>
    {mostrarGrafico && <ReactECharts 
                          option={graficoDados}
                          notMerge={true}/>}
  </>
  );

};
export default Grafico;