import { useRecoilState } from "recoil";
import { checkBoxSerieMap } from "../atom";

/**
 * Hook para definir se está ou não carregando. Mostra um 
 * overlay na tela se estiver carregando.
 */
const useCheckBoxSerieMap  = () => {
  return useRecoilState(checkBoxSerieMap);
};
export default useCheckBoxSerieMap;