import { atom } from "recoil";

// Nome da aplicação
export const nomeAplicacao = atom({
  key: "nomeAplicacao",
  default: "Minha Aplicação"
});

// Subtítulo da aplicação
export const subtitulo = atom({
  key: "subtitulo",
  default: ""
});

// Se está carregando ou não
export const loading = atom({
  key: 'loading',
  default: false
});

export const subTemas = atom({
  key: 'subTemas',
  default: []
});

export const arvoreState = atom({
  key: 'arvoreState',
  default: []
});

export const metadadoState = atom({
  key: 'metadadoState',
  default: []
});

export const valoresState = atom({
  key: 'valoresState',
  default: []
});

export const mapValoresState = atom({
  key: 'mapValoresState',
  default: new Map()
});

export const modalValorState = atom({ 
  key: 'modalValorState',
  default: false
});

export const modalMetadadoState = atom({
  key: 'modalMetadadoState',
  default: false
});

export const mostraListaSeries = atom({
  key: 'mostraListaSeries',
  default: false
});

export const arraySeries = atom({
  key: 'arraySeries',
  default: []
}); 

export const checkBoxValorCorrenteMap = atom({
  key: 'checkBoxValorCorrenteMap',
  default: new Map()
});

export const checkBoxSerieMap = atom({
  key: 'checkBoxSerieMap',
  default: new Map()
});