class SubTemaService {
    static async getSubTemas(idTema) {
        const url = "https://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/SerieGrafico/Tema/"+idTema;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET"
                , headers: {
                    'Accept': 'application/json, text/plain, */*'
                }
            }).then(response => response.json()).then(data => {
                resolve(data);
            });
        });
    }
}
export default SubTemaService;