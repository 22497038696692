import React, { useState } from 'react';
import { Button, Modal } from 'react-dsgov';
import TabelaSeries from '../../../components/TabelaSeries/TabelaSeries';
import useModalValorState from 'state/hooks/modais/useModalValorState';

const ValoresModal = (props) => {
    let valores = props.valores;
    const [modalValorAberta, setModalValorAberta] = useModalValorState(false);

    return (
        <Modal useScrim
            showCloseButton
            modalOpened={modalValorAberta}
            title={valores[0].serie}
            onCloseButtonClick={() => setModalValorAberta(false)}>
            <Modal.Body>
                <TabelaSeries series={valores} />
            </Modal.Body>
        </Modal>
    );
}
export default ValoresModal;