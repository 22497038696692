import React, { useState } from "react";
import useSubTemasState from "../../state/hooks/useSubtemasState";
import useArvoreState from "state/hooks/useArvoreState";
import ListaSeries from "components/ListaSeries";
import useLoadingState from "state/hooks/useLoadingState";
import classNames from "classnames";
import styles from './Subtema.module.scss';

const Subtema = () => {
    const [optionsSubTemas] = useSubTemasState([]);
    const [arvoreState] = useArvoreState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [loading, setLoading] = useLoadingState(false);

    const renderPlanoContas = (arvore) => {
        return (
            <ListaSeries arvore={arvore} />
        );
    };

    const handleAccordionClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div>
            {optionsSubTemas.length > 0 && <h3>Subtemas</h3>}

            <div className="panel-group" id="accordion">
                {!loading && optionsSubTemas.map((linha, index) => (
                    <div className="panel panel-default" key={index}>
                        <div
                            className={`panel-heading ${index === activeIndex ? "active" : ""}`}
                            onClick={() => handleAccordionClick(index)}
                        >
                            <h4 className={classNames("panel-title", styles.subtema)}>
                                <a data-toggle="collapse" data-parent="#accordion">
                                    {linha.label}
                                </a>
                            </h4>
                        </div>
                        <div id={`collapse${index}`} className={`panel-collapse collapse ${index === activeIndex ? "in" : ""}`}>
                            <div className="panel-body">
                                {arvoreState.map((arv) => {
                                    if (arv.subTema === linha.subTema && index === activeIndex) {
                                        return (
                                            <div key={arv.id}>
                                                {renderPlanoContas(arv.arvore)}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Subtema;