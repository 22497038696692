import React, { useState } from 'react';

const TabelaSeries = (props) => {
    let seriesFiltradas = props.series;


    return (
        <div>
            
            <table>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {seriesFiltradas.map((serie) => (
                        <tr key={serie.id}>
                            <td>{serie.data}</td>
                            <td>{serie.valor}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
export default TabelaSeries;   