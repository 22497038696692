const removeSerieDoArray = (arraySeries, copiaSerie, setArraySeries) => {
    let arraySeriesCopy = [...arraySeries];
    let index = arraySeriesCopy.findIndex((serie) => serie.id === copiaSerie.id);
    arraySeriesCopy.splice(index, 1);
    setArraySeries(arraySeriesCopy);
}

const ajustaCheckbox = (copiaSerie, selecionado, checkboxSerieMap, setCheckboxSerieMap) => {
    if(copiaSerie != null && copiaSerie.id != null){
        checkboxSerieMap.set(copiaSerie.id, selecionado);
    }
    setCheckboxSerieMap(checkboxSerieMap);
}



export {
    removeSerieDoArray,
    ajustaCheckbox
};