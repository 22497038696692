import React from "react";
import { Button, Checkbox } from "react-dsgov";
import SerieService from "services/SerieService";
import { useState } from "react";
import useMetadadoState from "state/hooks/useMetadadoState";
import useValoresState from "state/hooks/useValoresState";
import useModalValorState from "state/hooks/modais/useModalValorState";
import useModalMetadadoState from "state/hooks/modais/useModalMetadadoState";
import { Row, Col, Container } from "react-dsgov";
import useArraySeriesState from "state/hooks/arraySeriesState";
import { ajustaCheckbox, removeSerieDoArray } from 'util/funcoesComuns';
import useCheckBoxSerieMap from "state/hooks/useCheckBoxSerieMap";
import useMostraListaSeries from "state/hooks/useMostraListaSeries";
import classNames from "classnames";
import styles from "./ListaSeries.module.scss";


const ListaSeries = (arvore) => {

    const [, setMetadadoState] = useMetadadoState([]);
    const [, setValoresState] = useValoresState([]);
    const [modalValorAberta, setModalValorAberta] = useModalValorState(false);
    const [modalMetadadoAberta, setModalMetadadoAberta] = useModalMetadadoState(false);
    const [arraySeries, setArraySeries] = useArraySeriesState([]);
    //const of checkboxMap with idSerie and boolean
    const [checkboxSerieMap, setCheckboxSerieMap] = useCheckBoxSerieMap(new Map());
    const [expandedItems, setExpandedItems] = useState([]);
    const [mostraListaSeries, setMostraListaSeries] = useMostraListaSeries(false);

    const exibirMetadadosSerie = (serie) => {
        SerieService.getMetadadosSerie(serie.id).then((metadados) => {
            setMetadadoState(metadados);
            setModalMetadadoAberta(true);
        });
    };


    const exibirValoresSerie = (serie) => {
        
        SerieService.getValoresSerie(serie.id).then((valores) => {
            setValoresState(valores);
            setModalValorAberta(true);
        });
    };

    const downloadSerie = (serie) => {
        //redirects to the download of serie
        window.location.href = "https://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/SerieGrafico/Download/"+serie.id
    };

    const onSerieSelecionada = (serie, selecionado) => {
        let copiaSerie = Object.assign({}, serie);

        ajustaCheckbox(serie, selecionado, checkboxSerieMap, setCheckboxSerieMap);

        if (selecionado) {
            setArraySeries([...arraySeries, copiaSerie]);
        } else {
            removeSerieDoArray(arraySeries, copiaSerie, setArraySeries);
        }
        setMostraListaSeries(true);
    };

    const geraTabelaRecursiva = (items) => {

        const toggleExpansion = (itemId) => {
            if (expandedItems.includes(itemId)) {
                setExpandedItems(expandedItems.filter((id) => id !== itemId));
            } else {
                setExpandedItems([...expandedItems, itemId]);
            }
        };

        const renderRows = (items, level) =>
            items.map((serie) => (
                <React.Fragment key={serie.id}>
                    <Row className={styles.linha}>
                        <Col md={1}>
                            {serie.dataInicialSerie && (
                                <Checkbox
                                    id={`checkbox-serie-${serie.id}`}
                                    label={serie.nome}
                                    value={`checkbox-01-${serie.id}`}
                                    name="radio-items"
                                    checked={checkboxSerieMap.get(serie.id)}
                                    onChange={(event) => onSerieSelecionada(serie, event.target.checked)}
                                />
                            )}
                        </Col>
                        <Col md={5}>
                            <Row>
                                {/* Add columns based on the level */}
                                {Array.from({ length: level }).map((_, index) => (<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>))}
                                <Col>
                                    {
                                        serie.seriesFilhas && serie.seriesFilhas.length > 0 &&
                                        <span class={styles.expansor} onClick={() => toggleExpansion(serie.id)}>
                                            {expandedItems.includes(serie.id) ?
                                                <i className="fas fa-chevron-down"></i>
                                                :
                                                <i className="fas fa-chevron-right"></i>
                                            }
                                        </span>}
                                    {
                                        (serie.seriesFilhas == null || serie.seriesFilhas.length === 0) &&
                                        <span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>}

                                    {serie.planoContas} -
                                    <span className="tamanho-nome-serie ng-binding" dangerouslySetInnerHTML={{ __html: serie.descricao }}></span>
                                </Col>
                            </Row>
                        </Col>

                        <Col md={1}>
                            {serie.descricaoUnidade}
                        </Col>
                        <Col md={1}>
                            {serie.periodicidade}
                        </Col>
                        <Col md={1}>
                            {serie.dataInicialSerie != null ? new Date(serie.dataInicialSerie).toLocaleDateString('pt-BR') : null}
                        </Col>
                        <Col md={1}>
                            {serie.dataFinalSerie != null ? new Date(serie.dataFinalSerie).toLocaleDateString('pt-BR') : null}
                        </Col>
                        <Col md={1}></Col>

                        <Col md={1} >
                            <Row class={styles["linha-botoes"]}>
                                {serie.dataInicialSerie != null && (
                                    <span className="hint hint--left" data-hint="Valores da Série">
                                        <Button primary circle onClick={() => exibirValoresSerie(serie)} className={styles.botao}>
                                            <i className="fas fa-dollar-sign"></i>
                                        </Button>
                                    </span>
                                )}

                                {serie.dataInicialSerie != null && (
                                    <span className="hint hint--left" data-hint="Metadados da Série">
                                        <Button primary circle onClick={() => exibirMetadadosSerie(serie)} className={styles.botao}>
                                            <i className="fas fa-list-alt"></i>
                                        </Button>
                                    </span>
                                )}

                                {serie.dataInicialSerie != null && (
                                    <span className="hint hint--left" data-hint="Download">
                                        <Button primary circle onClick={() => downloadSerie(serie)} className={styles.botao}>
                                            <i className="fas fa-download"></i>
                                        </Button>
                                    </span>
                                )}

                            </Row>
                        </Col>
                    </Row>
                    {expandedItems.includes(serie.id) &&
                        serie.seriesFilhas &&
                        serie.seriesFilhas.length > 0 &&
                        renderRows(serie.seriesFilhas, level + 1)
                    }
                </React.Fragment>
            ));

        return (
            renderRows(items, 0)
        );
    };




    return (
        <Container fluid class={styles["lista-series"]}>
            <div class={styles.cabecalho}>
                <Row>
                    <Col md={1}>Selecionar</Col>
                    <Col md={5}>Série</Col>
                    <Col md={1}>Periodicidade</Col>
                    <Col md={1}>Unidade</Col>
                    <Col md={1}>Data Inicial</Col>
                    <Col md={1}>Data Final</Col>
                    <Col md={1}></Col>
                    <Col md={1}>Visualizar</Col>

                </Row>
            </div>
            {geraTabelaRecursiva(arvore.arvore)}
        </Container>
    );
};
export default ListaSeries;


