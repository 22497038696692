class ArvoreService {
    static async getArvore(idSubtema) {
        const url = "https://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/SerieGrafico/Arvore/Subtema/" + idSubtema;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET"
            }).then(response => response.json()).then(data => {
                resolve(data);
            });
        });
    }
}
export default ArvoreService;