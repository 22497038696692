import React from "react";
import Tema from "./Tema/Tema";
import SubTema from "./Subtema/Subtema";
import { Col, Container, Row } from "react-dsgov";
import EdicaoSerie from "./EdicaoSerie/EdicaoSerie";
import Grafico from "./Grafico/Grafico";

/**
 * Página principal do sistema. 
 */
const Principal = () => {
    return (
        <Container fluid>

            <Row>
                <Col>
                    <Tema />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SubTema />
                </Col>
            </Row>
            <Row>
                <Col>
                    <EdicaoSerie />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Grafico />
                </Col>
            </Row>

        </Container>
    );
};
export default Principal;