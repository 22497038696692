class CalculoValorService {
  
    //static function that gets the real value of valorSerie in ttps://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/Formulas/Deflacionar/:idSerie/8180
    static async getDeflacionar(idSerie) {
        const url = "https://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/Formulas/Deflacionar/"+idSerie+"/8180";
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET"
                , headers: {
                    'Accept': 'application/json, text/plain, */*'
                }
            }).then(response => response.json()).then(data => {
                //for each data append " - Preços constantes (IPCA)" at the end of the serie
                data.forEach(valor => {
                    valor.serie = valor.serie + " - Preços constantes (IPCA)";
                    valor.data = new Date(valor.data).toLocaleDateString("pt-BR");
                });
                resolve(data);
            });
        });
    }
  
}

export default CalculoValorService;