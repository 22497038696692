class TemaService {
    static async getTemas() { 
        const url = "https://series-temporais.tesouro.gov.br/backend-series-temporais/rest/Public/SerieGrafico/Temas";
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET"
            }).then(response => response.json()).then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            })
        });
    }
}
export default TemaService;
